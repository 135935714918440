import React, { Component } from "react"
import {
    Box,
    Flex,
    Spinner,
} from "@chakra-ui/react"
import AdCardLandscape from './Landscape'
import AdLoader from './AdLoader'
import InfiniteScroll from 'react-infinite-scroller'
import NoAdsBanner from '../NoAdsBanner'
import config from "../../../config"
import { scroller } from 'react-scroll'

const contentful = require("contentful");

const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN,
});

class AsyncAdsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ads: [],
            adSingleID: null,
            hasMore: false,
            currentPage: 0,
            isLoading: true
        }
    }
    componentDidMount() {
        this.loadAds(true)
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ ads: [], currentPage: 0, isLoading: true, hasMore: false },
            () => {
                if (this.props.scrolling) {

                    scroller.scrollTo(
                        "annonces",
                        {
                            smooth: true,
                            spy: true,
                            duration: 500,
                            delay: 100
                        }
                    );

                    // document.querySelector('#anchor-ads-link').click()
                }
                this.loadAds(true)
            }
        )
    }
    getOrder() {
        const { order } = this.props.filters
        if (order) {
            return order
        } else {
            return '-fields.publicationDate'
        }
    }
    getPriceRange() {
        const { price } = this.props.filters
        var minPriceFilter
        var maxPriceFilter
        if (price) {
            minPriceFilter = { "fields.price[gte]": price[0] }
            maxPriceFilter = { "fields.price[lte]": price[1] }
        } else {
            minPriceFilter = null
            maxPriceFilter = null
        }
        return { ...minPriceFilter, ...maxPriceFilter }
    }
    getActionFilter() {
        const { filters } = this.props
        const { action } = filters
        let actionFilter = null
        if (action !== 'all' && action !== null && action !== undefined) {
            actionFilter = { ["fields.action"]: action }
        }
        return actionFilter
    }
    getCategoryFilter() {
        const { filters } = this.props
        const { category } = filters
        let categoryFilter = null
        if (category !== 'all' && category !== null && category !== undefined) {
            categoryFilter = { ["fields." + category]: true }
        }
        return categoryFilter
    }
    getCountryFilter() {
        const { filters } = this.props
        const { country } = filters
        let countryFilter = null
        if (country !== 'all' && country !== null && country !== undefined) {
            countryFilter = { ["fields.country"]: country }
        }
        return countryFilter
    }

    nextPage() {
        const { currentPage, ads } = this.state
        if (ads.length > 0) {
            this.setState({ currentPage: currentPage + 1, hasMore: false },
                () => {
                    this.loadAds(false)
                }
            )
        }
    }
    loadAds(reset = false) {
        this.setState({ hasMore: false })
        const { ads, currentPage } = this.state
        const { universID, modelID, versionID, brandID, userID } = this.props
        // const priceFilter       = this.getPriceRange()
        const actionFilter = this.getActionFilter()
        const categoryFilter = this.getCategoryFilter()
        const countryFilter = this.getCountryFilter()
        var adsFilter
        if (universID) {
            adsFilter = { "fields.refUnivers.sys.id": universID }
        }
        if (modelID) {
            adsFilter = { "fields.refModel.sys.id": modelID }
        }
        if (versionID) {
            adsFilter = { "fields.refVersion.sys.id": versionID }
        }
        if (brandID) {
            adsFilter = { "fields.refBrand.sys.id": brandID }
        }
        if (userID) {
            adsFilter = { "fields.user": userID }
        }
        let baseQuery = {
            content_type: "ad",
            locale: process.env.GATSBY_LANG,
            order: this.getOrder(),
            limit: this.props.pageLimite ? this.props.pageLimite : 20,
            skip: reset ? 0 : (currentPage) * (this.props.pageLimite ? this.props.pageLimite : 20),
        }
        let query = {
            ...baseQuery,
        }

        query = adsFilter ? { ...query, ...adsFilter } : { ...query }
        query = categoryFilter ? { ...query, ...categoryFilter } : { ...query }
        query = actionFilter ? { ...query, ...actionFilter } : { ...query }
        query = countryFilter ? { ...query, ...countryFilter } : { ...query }

        client
            .getEntries(query)
            .then(response =>
                this.setState({
                    ads: reset ? response.items : ads.concat(response.items),
                    currentPage: currentPage,
                    hasMore: response.total > (currentPage * this.props.pageLimite ? this.props.pageLimite : 20) ? true : false,
                    totalAds: response.total,
                    isLoading: false
                })
            )
            .catch(console.error)
        if (currentPage >= 1) {

            typeof window !== "undefined" && window.gtag
                && window.gtag('event', 'page_view', {
                    page_title: `page-${currentPage + 1}`,
                    page_location: `${config.domains[process.env.GATSBY_LANG]}/`,
                    page_path: `/${this.props.baseSlug}page-${(currentPage + 1)}/${this.props.baseSlug}page-${(currentPage + 1)}/`,
                })
        }
    }

    render() {
        const { ads, adSingleID, hasMore, isLoading } = this.state
        const { filters, openSelf } = this.props
        const FullDate = (props) => {
            const date = new Date(props.date);
            return (
                date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
            )
        }

        const Loader = () => (
            <Flex key={0} height="80px" width="100%" borderRadius="sm" boxShadow="sm" justify="center" background="white" alignItems="center"><Spinner color="blue.600" /></Flex>
        )
        return (
            <>
                <Box key={filters}
                    py="1rem"
                    id='annonces'
                    minH='100vh'
                >
                    <InfiniteScroll
                        key={filters}
                        useWindow={true}
                        pageStart={0}
                        loadMore={this.nextPage.bind(this)}
                        hasMore={hasMore}
                        threshold={1000}
                        loader={<Loader key={0} />}
                    >
                        <Box
                            minH='100vh'
                        >
                            {ads.length ?
                                ads.map((edge) =>
                                    adSingleID && openSelf && adSingleID === edge.sys.id ?
                                        <AdLoader
                                            adID={adSingleID}
                                            key={`adportrait-${edge.sys.id}`}
                                            handleUnload={
                                                () => {
                                                    this.setState({ adSingleID: null })
                                                }
                                            }
                                        />
                                        :
                                        <AdCardLandscape
                                            to={edge.fields.slug}
                                            contentful_id={edge.sys.id}

                                            openAction={
                                                () => {
                                                    this.setState({ adSingleID: edge.sys.id })
                                                }
                                            }

                                            action={edge.fields.action}
                                            // isInIframe={this.props.openSelf}
                                            openSelf={openSelf}
                                            name={edge.fields.name}
                                            price={edge.fields.price}
                                            currency={edge.fields.currency}
                                            brand={(edge.fields.refBrand) ? edge.fields.refBrand.fields.name : null}
                                            region={edge.fields.region}
                                            country={edge.fields.country}
                                            key={`landscapelarge-${edge.sys.id}`}
                                            images={edge.fields.pictures}
                                            seller={edge.fields.user ? true : false}
                                            publicationDate={<FullDate date={edge.fields.publicationDate} />}
                                            isPremium={edge.fields.isPremium}
                                        />
                                ) : isLoading ? <Flex justify="center"><Spinner size="sm" /></Flex> : <NoAdsBanner />}
                        </Box>
                        {/* <Box display="none">
                            <Scrollchor id="anchor-ads-link" to="#annonces">annonces</Scrollchor>
                        </Box> */}
                    </InfiniteScroll>
                </Box>
            </>
        )
    }
}

export default AsyncAdsList