import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
    Box,
    Button,
    Stack
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
const siteConfig = require('../../config')

const NoAdsBanner = (props) => {
    const locale = useIntl().locale
    return (
        <Stack
            border='dashed 2px'
            borderColor='blue.300'
            borderRadius='3px'
            p='2.5rem'
            color="blue.900"
            wrap="wrap"
            spacing='.5rem'
        >
            <Box>
                <span role="img" aria-label="Sad">😢</span>&nbsp;&nbsp;
                <FormattedMessage id="main.no.ads.message" />
            </Box>

            <Box>
                <Button
                    as={GatsbyLink}
                    to={siteConfig.links.publish[locale]}
                    size="xs"
                    colorScheme="yellow"
                >
                    <FormattedMessage id="main.no.ads.button.label" /> →
                </Button>
            </Box>
        </Stack>
    )
}
export default NoAdsBanner